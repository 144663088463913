import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Helmet from "react-helmet"

// import { FormEventAnalytics } from "../utils/analytics"
import Layout from "../components/layout"

import SEO from "../components/seo"

import Arrow from "../components/arrow"
import { setUTMSource } from "../utils/storage"
import { DirectiveLocation } from "graphql"

import PartnerSlider from "../components/partnerSlider"

import axios from "../utils/axios"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalShow: false,
      firstVisit: false,
    }
  }

  componentDidMount() {
    // window.addEventListener('beforeunload', function (event) {
    //   localStorage.clear();
    // }, false);
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Product",
              name: "Fuse Classroom",
              image: "https://fuseclassroom.com/images/ogimage.jpg",
              description:
                "AI-enabled education platform with smart AI features that empower both school administrators, instructors, and students.",
              brand: "Fusemachines",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "3.6",
                bestRating: "5",
                worstRating: "1",
                ratingCount: "512",
              },
            })}
          </script>
        </Helmet>
        <SEO
          title={"AI-Enabled Learning Platform"}
          titleDesc={
            "Fuse Classroom, an AI-enabled education platform with smart AI features that provide a better online learning experience to students and faculty members."
          }
          titleKeywords={
            "fuse classroom, lms, online education, online learning, online classes, ai features, education platform, smart ai, ai enabled education platform, e learning platforms"
          }
          fbDes={" Fuse Classroom comprises smart AI features to provide a better online learning experience."}
          twitDesc={" Fuse Classroom comprises smart AI features to provide a better online learning experience."}
          seoLink={""}
        />

        {/* top section */}
        <section role="top--section" className="py-md-4 overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center mt-4 justify-content-between">
              <Col md="5">
                <div className="">
                  <h1>AI Enabled Education Platform</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    Fuse Classroom brings you a reimagined and all inclusive education platform with smart AI features
                    that empower both school administrators, instructors, and students.
                  </div>

                  <div className="main-cta">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg mt-4 font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Home_Request_Demo")}
                      state={{ page: "landing_page" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                    <Link
                      to="/platform/"
                      className="btn btn-outline-success mr-3 btn-lg mt-4 font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Home_Learn_More")}
                      state={{ page: "Home_Learn_More" }}
                      id="learn-more__home-page"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left mb-5">
                  <img
                    src="/images/fc-platform.webp"
                    className="platform-img shadow-lg img-rounded"
                    alt="landing"
                    width="700"
                    height="auto"
                  />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* School Partners */}
        <PartnerSlider />
        {/* School Partners */}

        {/* Interactive online learning */}
        <section className="py-md-5 bg-80 right">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-4">
                <img
                  src="/images/platform/online-learning.webp"
                  alt="instructor"
                  className="img-fluid shadow-lg img-rounded"
                  width="630"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Interactive Online Learning</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Fuse Classroom gives instructors and students an interactive online platform for a new type of
                    learning experience.
                  </div>
                  <ul className="arrow my-4 h5 text-black font-weight-normal">
                    <li>Track and admit students from right within the platform</li>
                    <li>Administrators can create new courses and find instructors</li>
                    <li>Monitor student progress and mental health, with our counselor feature</li>
                  </ul>

                  <Link
                    to="/platform/"
                    className="h5 mr-3 mt-4 font-weight-semibold text-success"
                    // onClick={() => FormEventAnalytics("Home_Learn_More")}
                    state={{ page: "Home_Learn_More" }}
                    id="learn-more__online-learning"
                  >
                    LEARN MORE <Arrow />
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* interactive online learning end */}

        {/* smart engagement */}
        <section className="py-md-5 my-lg-5">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/smart-engagement.webp"
                  alt="instructor"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Smart Engagement</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Using AI, Fuse Classroom uses data-driven methods to engage instructors and students.
                  </div>
                  <ul className="arrow my-4 h5 text-black font-weight-normal">
                    <li>Smart notifications based on behavior and activity</li>
                    <li>Optimized learning with personalized content recommendations</li>
                    <li>AI student status engine</li>
                  </ul>
                  <Link
                    to="/platform/"
                    className="h5 mr-3 mt-4 font-weight-semibold text-success"
                    // onClick={() => FormEventAnalytics("Home_Learn_More")}
                    state={{ page: "Home_Learn_More" }}
                    id="learn-more__engagement"
                  >
                    LEARN MORE <Arrow />
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* smart engagement end */}

        {/* accelerate for digital transformation */}
        <section className="py-md-5 bg-80 right">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md={{ span: 6, order: 2 }} className="my-5">
                <img
                  src="/images/home-digital-transformation.webp"
                  alt="instructor"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>

              <Col md={{ span: 5, order: 1 }}>
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Accelerate Your Digital Transformation</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    Fuse Classroom digitizes the business operations of your education institution with curated
                    features.
                  </div>
                  <ul className="arrow my-4 h5 text-black font-weight-normal">
                    <li>Automatic billing, canteen, and bus schedules</li>
                    <li>Parents/guardians information management system</li>
                    <li>Library, events, sports, clubs, career services, feedback, and more</li>
                  </ul>
                  <Link
                    to="/platform/"
                    className="h5 mr-3 mt-4 font-weight-semibold text-success"
                    // onClick={() => FormEventAnalytics("Home_Learn_More")}
                    state={{ page: "Home_Learn_More" }}
                    id="learn-more__digital-transformation"
                  >
                    LEARN MORE <Arrow />
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* accelerate for digital transformation end */}

        {/* ensure successful adoption */}
        <section className="py-md-5 mt-lg-5">
          <article className="container">
            <Row className="align-items-center justify-content-between">
              <Col md="6" className="my-5">
                <img
                  src="/images/adoption.webp"
                  alt="instructor"
                  className="img-fluid shadow-lg img-rounded"
                  width="500"
                  height="auto"
                />
              </Col>
              <Col md="5">
                <div className="py-md-4">
                  <h2 className="font-weight-bold">Ensure Successful Adoption</h2>
                  <div className="h5 mt-4 text-black font-weight-normal">
                    To provide consistent support to our college and university partners, our specialists ensure the
                    highest quality online experience, even as needs change and evolve.
                  </div>
                  <ul className="arrow my-4 h5 text-black font-weight-normal">
                    <li>Support and training for management and instructors</li>
                    <li>Dedicated teams of community and customer success managers</li>
                    <li>Advanced security and access control features</li>
                  </ul>
                  <Link
                    to="/implementation/"
                    className="h5 mr-3 mt-4 font-weight-semibold text-success"
                    // onClick={() => FormEventAnalytics("Home_Learn_More")}
                    state={{ page: "Home_Learn_More" }}
                    id="learn-more__adoption"
                  >
                    {" "}
                    LEARN MORE <Arrow />
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* ensure successful adoption end */}

        {/* call to action */}
        <section className="pb-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="8">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in seeing the platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("Hom_Call_to_action_Request_Demo")}
                    state={{ page: "landing_page" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
